import store from '@/store/index'

// 门票退款有效期下拉
export const daysBeforeList = [
  {
    name: '后1天',
    value: '1',
  },
  {
    name: '后2天及以内',
    value: '2',
  },
  {
    name: '后3天及以内',
    value: '3',
  },
  {
    name: '后4天及以内',
    value: '4',
  },
  {
    name: '后5天及以内',
    value: '5',
  },
  {
    name: '后6天及以内',
    value: '6',
  },
  {
    name: '后7天及以内',
    value: '7',
  },
  {
    name: '后8天及以内',
    value: '8',
  },
  {
    name: '后9天及以内',
    value: '9',
  },
  {
    name: '后10天及以内',
    value: '10',
  },
  {
    name: '后14天及以内',
    value: '14',
  },
  {
    name: '后15天及以内',
    value: '15',
  },
  {
    name: '后21天及以内',
    value: '21',
  },
  {
    name: '后30天及以内',
    value: '30',
  },
  {
    name: '后60天及以内',
    value: '60',
  },
  {
    name: '后90天及以内',
    value: '90',
  },
  {
    name: '后120天及以内',
    value: '120',
  },
]

export const daysAfterList = [
  {
    name: '当日及以上',
    value: '0',
  },
  {
    name: '前1天及以上',
    value: '1',
  },
  {
    name: '前2天及以上',
    value: '2',
  },
  {
    name: '前3天及以上',
    value: '3',
  },
  {
    name: '前4天及以上',
    value: '4',
  },
  {
    name: '前5天及以上',
    value: '5',
  },
  {
    name: '前6天及以上',
    value: '6',
  },
  {
    name: '前7天及以上',
    value: '7',
  },
  {
    name: '前8天及以上',
    value: '8',
  },
  {
    name: '前9天及以上',
    value: '9',
  },
  {
    name: '前10天及以上',
    value: '10',
  },
  {
    name: '前14天及以上',
    value: '14',
  },
  {
    name: '前15天及以上',
    value: '15',
  },
  {
    name: '前21天及以上',
    value: '21',
  },
  {
    name: '前30天及以上',
    value: '30',
  },
  {
    name: '前60天及以上',
    value: '60',
  },
  {
    name: '前90天及以上',
    value: '90',
  },
  {
    name: '前120天及以上',
    value: '120',
  },
]

export const hotelDaysAfterList = [
  {
    name: '当天',
    value: '0',
  },
  {
    name: '前1天',
    value: '1',
  },
  {
    name: '前2天',
    value: '2',
  },
  {
    name: '前3天',
    value: '3',
  },
  {
    name: '前4天',
    value: '4',
  },
  {
    name: '前5天',
    value: '5',
  },
  {
    name: '前6天',
    value: '6',
  },
  {
    name: '前7天',
    value: '7',
  },
]

export const timeAfterList = [
  {
    name: '6:00前',
    value: '6',
  },
  {
    name: '8:00前',
    value: '8',
  },
  {
    name: '9:00前',
    value: '9',
  },
  {
    name: '10:00前',
    value: '10',
  },
  {
    name: '11:00前',
    value: '11',
  },
  {
    name: '12:00前',
    value: '12',
  },
  {
    name: '13:00前',
    value: '13',
  },
  {
    name: '14:00前',
    value: '14',
  },
  {
    name: '15:00前',
    value: '15',
  },
  {
    name: '16:00前',
    value: '16',
  },
  {
    name: '17:00前',
    value: '17',
  },
  {
    name: '18:00前',
    value: '18',
  },
  {
    name: '19:00前',
    value: '19',
  },
  {
    name: '20:00前',
    value: '20',
  },
  {
    name: '21:00前',
    value: '21',
  },
  {
    name: '22:00前',
    value: '22',
  },
  {
    name: '23:00前',
    value: '23',
  },
  {
    name: '24:00前',
    value: '24',
  },
]

//门票退款类别

export const ticketRefundType = [
  {
    name: '固定金额 - X（扣款金额）',
    value: '0',
  },
  {
    name: '固定金额 - X（扣款系数0.0~1.0）',
    value: '1',
  },
]
//酒店民宿类别

export const ticketRefundType1 = [
  {
    name: '固定金额 - X（扣款金额）',
    value: '0',
  },
  {
    name: '固定金额 - X（扣款系数）',
    value: '1',
  },
  {
    name: '免费退',
    value: '2',
  },
]

export const ticketRefundRatioList = [
  {
    name: '0%',
    value: 0,
  },
  {
    name: '10%',
    value: 0.1,
  },
  {
    name: '20%',
    value: 0.2,
  },
  {
    name: '30%',
    value: 0.3,
  },
  {
    name: '40%',
    value: 0.4,
  },
  {
    name: '50%',
    value: 0.5,
  },
  {
    name: '60%',
    value: 0.6,
  },
  {
    name: '70%',
    value: 0.7,
  },
  {
    name: '80%',
    value: 0.8,
  },
  {
    name: '90%',
    value: 0.9,
  },
]

// 用户来源
export const regSourceList = [
  {
    text: '未知',
    value: '0',
  },
  {
    text: 'H5',
    value: '1',
  },
  {
    text: '微信小程序',
    value: '2',
  },
  {
    text: '安卓app',
    value: '3',
  },
  {
    text: '苹果app',
    value: '4',
  },
]

// 车船票 订单状态
export const shipBusStateList = [
  { text: '待支付', value: 0 },
  { text: '待使用', value: 1 },
  { text: '已完成', value: 5 },
  { text: '已关闭', value: 6 },
]

// 订单状态
export const orderStateList = [
  {
    text: '待付款',
    value: '0',
  },
  {
    text: '待使用',
    value: '1',
  },
  {
    text: '待发货',
    value: '2',
  },
  {
    text: '待签收',
    value: '3',
  },
  {
    text: '待评价',
    value: '4',
  },
  {
    text: '已完成',
    value: '5',
  },
  {
    text: '已关闭',
    value: '6',
  },
]

//酒店民宿
export const orderStateList2 = [
  {
    text: '待付款',
    value: '0',
  },
  {
    text: '待入住',
    value: '1',
  },
  {
    text: '待发货',
    value: '2',
  },
  {
    text: '待签收',
    value: '3',
  },
  {
    text: '待评价',
    value: '4',
  },
  {
    text: '已完成',
    value: '5',
  },
  {
    text: '已关闭',
    value: '6',
  },
  {
    text: '待确认',
    value: '102',
  },
  {
    text: '待退款',
    value: '50',
  },
]

//景点门票
export const orderStateList3 = [
  {
    text: '待付款',
    value: '0',
  },
  {
    text: '待使用',
    value: '1',
  },
  {
    text: '待发货',
    value: '2',
  },
  {
    text: '待签收',
    value: '3',
  },
  {
    text: '待评价',
    value: '4',
  },
  {
    text: '已完成',
    value: '5',
  },
  {
    text: '已关闭',
    value: '6',
  },
  {
    text: '出票中',
    value: '101',
  },
  {
    text: '待退款',
    value: '50',
  },
  {
    text: '待确认',
    value: '102',
  },
]

//研学订单
export const orderStateList4 = [
  {
    text: '待付款',
    value: '0',
  },
  {
    text: '已报名',
    value: '1',
  },
  // {
  //   text: '已报名',
  //   value: '2',
  // },
  // {
  //   text: '已报名',
  //   value: '3',
  // },
  // {
  //   text: '待评价',
  //   value: '4',
  // },
  {
    text: '已完成',
    value: '5',
  },
  {
    text: '已关闭',
    value: '6',
  },
  // {
  //   text: '出票中',
  //   value: '101',
  // },
  {
    text: '待退款',
    value: '50',
  },
  // {
  //   text: '待确认',
  //   value: '102',
  // },
]
// 床类型
export const bedTypeList = [
  {
    text: '大床',
    name: '大床',
    value: '0',
  },
  {
    text: '双床',
    name: '双床',
    value: '1',
  },
  {
    text: '大床/双床',
    name: '大床/双床',
    value: '2',
  },
]
// 订单来源
export const orderSourceList = [
  {
    text: '农家商城',
    value: '1',
  },
  {
    text: '农家乐',
    value: '2',
  },
  // {
  //   text: '民宿',
  //   value: '3',
  // },
  {
    text: '农事体验',
    value: '4',
  },
  // {
  //   text: '垂钓',
  //   value: '5',
  // },
  {
    text: '景点门票',
    value: '12',
  },
  {
    text: '酒店民宿',
    value: '13',
  },
  {
    text: '组合套餐',
    value: '14',
  },
  {
    text: '船票',
    value: '15',
  },
  {
    text: '车票',
    value: '16',
  },
]
// 退款情况
export const refundIsList = [
  {
    text: '等待处理',
    value: '1',
  },
  {
    text: '已退款',
    value: '2',
  },
  {
    text: '已拒绝',
    value: '3',
  },
  {
    text: '退款失败',
    value: '5',
  },
]
// 营业周期
export const operatingCycle = [
  {
    name: '周一',
    value: '1',
  },
  {
    name: '周二',
    value: '2',
  },
  {
    name: '周三',
    value: '3',
  },
  {
    name: '周四',
    value: '4',
  },
  {
    name: '周五',
    value: '5',
  },
  {
    name: '周六',
    value: '6',
  },
  {
    name: '周日',
    value: '7',
  },
]
// 分量单位
export const unitDataList = [
  {
    name: '盘',
    value: '盘',
  },
  {
    name: '个',
    value: '个',
  },
  {
    name: '份',
    value: '份',
  },
  {
    name: 'g',
    value: 'g',
  },
  {
    name: 'KG',
    value: 'KG',
  },
  {
    name: '两',
    value: '两',
  },
]
// 针对产品类型
export const productTypeList = () => {
  const { byProductType } = store.state.textFiles
  return [
    {
      name: '全场',
      value: '0',
      type: 'all',
    },
    ...byProductType,
  ]
}

// 优惠券-发放方式
export const giveTypeList = [
  {
    name: '用户手动领取',
    text: '用户手动领取',
    value: '1',
  },
  {
    name: '自动发放到所有用户',
    text: '自动发放到所有用户',
    value: '2',
  },
  {
    name: '只限二维码领取',
    text: '只限二维码领取',
    value: '3',
  },
  {
    name: '营销活动',
    text: '营销活动',
    value: '4',
  },
  {
    name: '付款时自动领取',
    text: '付款时自动领取',
    value: '5',
  },
]

// 链接类型
export const relateTypeList = [
  {
    text: '外链',
    name: '外链',
    value: '1',
  },
  {
    text: '内部链接',
    name: '内部链接',
    value: '2',
  },
  {
    text: '商品',
    name: '商品',
    value: '3',
  },
  {
    text: '店铺',
    name: '店铺',
    value: '4',
  },
  {
    text: '活动',
    name: '活动',
    value: '5',
  },
  {
    text: '直播',
    name: '直播',
    value: '6',
  },
  {
    text: '专题页',
    name: '专题页',
    value: '7',
  },
  {
    text: '抽奖',
    name: '抽奖',
    value: '8',
  },
  {
    text: '跳转小程序',
    name: '跳转小程序',
    value: '9',
  },
  {
    text: '更多',
    name: '更多',
    value: '10',
  },
  {
    text: '景点门票',
    name: '景点门票',
    value: '11',
  },
  {
    text: '正在开发中',
    name: '正在开发中',
    value: '-1',
  },
  {
    text: '无',
    name: '无',
    value: '-2',
  },
]
// 游玩天数
export const dayNumList = [
  {
    text: '1天',
    value: '1',
  },
  {
    text: '2天',
    value: '2',
  },
  {
    text: '3天',
    value: '3',
  },
  {
    text: '其他',
    value: '0',
  },
]
// 商户类型
export const subMerTypeList = [
  //   {
  //     name: '自然人',
  //     value: '1'
  //   },
  {
    name: '企业',
    value: '2',
  },
  {
    name: '个体工商户',
    value: '3',
  },
]

// 商户验证类型
export const subMerRuleTypeList = [
  {
    text: '待平台审核',
    value: '001',
  },
  {
    text: '平台审核失败',
    value: '003',
  },
  {
    text: '待签约',
    value: '0',
  },
  {
    text: '等待审核',
    value: '1',
  },
  {
    text: '开通成功',
    value: '2',
  },
  {
    text: '开通失败',
    value: '3',
  },
]
// 证件类型
export const certificateTypeList = [
  {
    name: '身份证',
    value: 'I',
  },
  {
    name: '护照',
    value: 'P',
  },
  {
    name: '军官证',
    value: 'G',
  },
  {
    name: '其他证件',
    value: 'U',
  },
]
// 证件类型
export const companyCertTypeList = [
  {
    name: '营业执照',
    value: 'U',
  },
  {
    name: '组织机构代码证',
    value: 'Y',
  },
  {
    name: '统一社会信用代码',
    value: 'V',
  },
]
// 收款账户（选择企业必须用对公账户）
export const accountTypeList = [
  {
    name: '借记卡',
    value: '401',
  },
  {
    name: '企业户',
    value: '601',
  },
  {
    name: '二类户',
    value: '701',
  },
  {
    name: '三类户',
    value: '702',
  },
]
// 评论来源
export const relationTypeList = [
  {
    name: '农家乐',
    text: '农家乐',
    value: '0',
  },
  // {
  //   name: '民宿农舍',
  //   text: '民宿农舍',
  //   value: '1'
  // },
  {
    name: '农事体验',
    text: '农事体验',
    value: '2',
  },
  {
    name: '农家特产',
    text: '农家特产',
    value: '3',
  },
  {
    name: '景点门票',
    text: '景点门票',
    value: '7',
  },
  {
    name: '酒店民宿',
    text: '酒店民宿',
    value: '8',
  },
  {
    name: '乡村印象',
    text: '乡村印象',
    value: '4',
  },
  {
    name: '精彩活动',
    text: '精彩活动',
    value: '5',
  },
  {
    name: '农旅线路',
    text: '农旅线路',
    value: '6',
  },
]
// 推送端
export const pushPlat = [
  {
    name: 'IOS',
    text: 'IOS',
    value: '0',
  },
  {
    name: '安卓',
    text: '安卓',
    value: '1',
  },
  {
    name: '全平台',
    text: '全平台',
    value: '2',
  },
]

// 就业管理 - 部门
export const partList = [
  {
    name: '运营部',
    text: '运营部',
    value: '0',
  },
  {
    name: '开发部',
    text: '开发部',
    value: '1',
  },
  {
    name: '行政部',
    text: '行政部',
    value: '2',
  },
  {
    name: '物流部',
    text: '物流部',
    value: '3',
  },
]

// 宽带情况
export const broadbandSituation = () => {
  const { byBroadbandType } = store.state.textFiles
  return byBroadbandType
}
// 可否加床
export const extraBed = () => {
  const { byExtraBedType } = store.state.textFiles
  return byExtraBedType
}

// 适用人数
export const suitNum = () => {
  let { suitNum } = store.state.textFiles
  return suitNum
}

// 是否禁烟
export const smoking = () => {
  const { byWhetherType } = store.state.textFiles
  return byWhetherType
}

// 无线WIFI
export const wirelessWIFI = () => {
  const { byWIFIType } = store.state.textFiles
  return byWIFIType
}

// 床型
export const bedType = () => {
  const { byBedType } = store.state.textFiles
  return byBedType
}

// 窗户
export const theWindow = () => {
  const { byWindowType } = store.state.textFiles
  return byWindowType
}

// 酒店星级
export const hotelStarRating = () => {
  const { byStarHotelType } = store.state.textFiles
  return byStarHotelType
}

// 酒店产品主题
export const hotelSubject = () => {
  const { bySubjectType } = store.state.textFiles
  return bySubjectType
}

// 酒店类型
export const hotelType = () => {
  const { byHotelType } = store.state.textFiles
  return byHotelType
}

// 早餐
export const hotelBreakFast = () => {
  const { byHotelBreakFastType } = store.state.textFiles
  return byHotelBreakFastType
}

// 所属品牌
export const brandOwnership = () => {
  const { byBrandType } = store.state.textFiles
  return byBrandType
}

// 票种
export const thirdTypeDataList = () => {
  const { byTicketType } = store.state.textFiles
  return byTicketType
}

//商品类型
export const goodsTypeList = () => {
  const { byGoodsType } = store.state.textFiles
  return byGoodsType
}

/* 二级商户相关 */
// 法定代表人/经营者证件类型
export const byCertificateTypeList = () => {
  const { byCertificateType } = store.state.textFiles
  return byCertificateType
}

// 二级商户证件类型
export const byCompanyCertTypeList = () => {
  const { byCompanyCertType } = store.state.textFiles
  return byCompanyCertType
}

// 个人商户类别
export const bySubMerClassList = () => {
  const { bySubMerClass } = store.state.textFiles
  return bySubMerClass
}

// 商户联系人业务标识
export const bySubMerContactTypeList = () => {
  const { bySubMerContactType } = store.state.textFiles
  return bySubMerContactType
}

// 二级商户申请服务
export const bySzxcApplyServiceList = () => {
  const { bySzxcApplyService } = store.state.textFiles
  return bySzxcApplyService
}

// 二级商户类型
export const bySubMerTypeList = () => {
  const { bySubMerType } = store.state.textFiles
  return bySubMerType
}

// 反馈类型
export const byAuditTypeList = () => {
  const { byAuditType } = store.state.textFiles
  return byAuditType
}

export const activeTypeList = [
  {
    name: '农家乐',
    text: '农家乐',
    value: '3',
  },
  // {
  //   name: '民宿农舍',
  //   text: '民宿农舍',
  //   value: '2'
  // },
  {
    name: '农事体验',
    text: '农事体验',
    value: '4',
  },
  //   {
  //     name: '农事体验-采摘',
  //     text: '农事体验-采摘',
  //     value: '4'
  //   },
  //   {
  //     name: '农事体验-垂钓',
  //     text: '农事体验-垂钓',
  //     value: '5'
  //   },
  // {
  //   name: '景点',
  //   text: '景点',
  //   value: '1'
  // },
  {
    name: '酒店民宿',
    text: '酒店民宿',
    value: '7',
  },
  {
    name: '景点门票',
    text: '景点门票',
    value: '6',
  },
]

export const refundExplainConfig = [
  {
    name: '随时退',
    value: '0',
  },
  {
    name: '不可退',
    value: '1',
  },
  {
    name: '有效期可退',
    value: '2',
  },
]

export const configTypeList = [
  {
    name: '农家特产',
    value: '1',
  },
  {
    name: '乡村视频',
    value: '2',
  },
  {
    name: '新闻公告',
    value: '3',
  },
]

export default {
  activeTypeList,
  orderStateList,
  orderStateList2,
  orderStateList3,
  bedTypeList,
  orderSourceList,
  refundIsList,
  operatingCycle,
  unitDataList,
  relateTypeList,
  dayNumList,
  subMerTypeList,
  subMerRuleTypeList,
  certificateTypeList,
  companyCertTypeList,
  accountTypeList,
  relationTypeList,
  pushPlat,
  partList,
  refundExplainConfig,
  goodsTypeList,
  productTypeList,
  broadbandSituation,
  extraBed,
  smoking,
  wirelessWIFI,
  bedType,
  theWindow,
  hotelStarRating,
  brandOwnership,
  thirdTypeDataList,
  byCertificateTypeList,
  byCompanyCertTypeList,
  bySubMerClassList,
  bySubMerContactTypeList,
  bySzxcApplyServiceList,
  bySubMerTypeList,
  regSourceList,
  byAuditTypeList,
  configTypeList,
}
